import { Link } from 'gatsby-theme-material-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Typography, Grid } from '@mui/material';
import { css } from '@emotion/react';

import MainLayout from '../components/MainLayout';
import MainHero from '../components/MainHero';
import Block from '../components/Block';
import Ingress from '../components/Ingress';

export function Head() {
  return <title>Veikko Mäkinen - In English</title>;
}

export default function English() {
  return (
    <MainLayout>
      <MainHero
        title="Technologies - Systems - People"
        subtitle="Strategy - Development - Leadership"
      />
      <Block>
        <Grid container rowSpacing={1} spacing={{ sm: 2, lg: 4 }}>
          <Grid item xs={12} sm={8} p={2}>
            <Typography variant="h4" gutterBottom>
              Helping companies and entrepreneurs
            </Typography>
            <Ingress>
              A company or an entrepreneur could often use someone to spar with.
              Be it some strategic decisions to be made or just about organizing
              smaller daily operations, having someone with a broad hands-on
              experience can be invaluable.
            </Ingress>
            <Ingress>
              I have over 20 years of experience covering a wide range of different
              areas of business management and entrepreneurship. My strong suits
              are software business, financial management and leadership but
              developing sales, marketing and customer service is just as well
              close to my heart.
            </Ingress>
            <Ingress>
              And with my hands-on experience I am able to help with the actual heavy
              lifting, too. Need help to develop a proof-of-concept version of
              your software idea or design a pitch material? Need help to analyze
              your financial data? Don&apos;t worry, I can help you!
            </Ingress>
            <Ingress>
              <Link to="/contact" color="secondary">
                How can I help? Let&apos;s connect!
              </Link>
            </Ingress>
          </Grid>
          <Grid item xs={12} sm={4} p={2}>
            <StaticImage
              src="../images/makinen-veikko-pysty.jpg"
              alt="Profile picture"
              placeholder="blurred"
              layout="constrained"
              imgStyle={{
                borderRadius: '5px',
                boxShadow: '2px 2px 5px #888',
              }}
              css={css`
                border-radius: 5px;
                box-shadow: 2px 2px 5px #888;
              `}
            />
          </Grid>
        </Grid>
      </Block>
    </MainLayout>
  );
}
